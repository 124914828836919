body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 20px;
  }
  
  ol, ul {
    padding-left: 30px;
  }
  
  .shelf-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  .container {
    background: #fff;
    border: 1px solid #ccc;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
    background-image: url(./images/container.jpg);
    background-size: 42px 42px;
  }
  
  .black-container {
    background: #fff;
    border: 1px solid #333;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
    background-image: url(./images/black_container.jpg);
    background-size: 42px 42px;
  }
  
  .green-container {
    background: #fff;
    border: 1px solid #ccc;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
    background-image: url(./images/green2.jpg);
    background-size: 42px 42px;
  }
  
  .green-select {
    background: #fff;
    border: 1px solid #333;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
    background-image: url(./images/green2.jpg);
    background-size: 42px 42px;
  }
  
  .noshow-container {
    background: #fff;
    border: 1px solid #ccc;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
  }
  
  .empty-container {
  background: #fff;
  border: 0px solid #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 46px;
  height: 46px;
  margin-right: 1px;
  margin-top: 1px;
  padding: 0;
  text-align: center;
  width: 32px;
  }
  
  .bigger-empty-container {
    background: #fff;
    border: 0px solid #fff;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
    }
  
  /* .empty-container {
    background: #fff;
    border: 0px solid #fff;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    height: 46px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0;
    text-align: center;
    width: 44px;
    } */
  
  .thin-container {
  background: #fff;
  border: 0px solid #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 46px;
  height: 46px;
  margin-right: 1px;
  margin-top: 1px;
  padding: 0;
  text-align: center;
  width: 44px;
  }
  
  
  .container:focus {
    outline: none;
  }
  
  .kbd-navigation .container:focus {
    background: #ddd;
  }
  
  .rack {
    display: flex;
    flex-direction: row;
  }
  
  .rack-info {
    margin-left: 20px;
  }
  
  /* .rack-shelf {
    width: 250px;
  } */
  